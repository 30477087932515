import React, { useContext, useState, useEffect } from 'react'
import navigate from '../utils/navigate'
import { Context as LeaveContext } from '../components/LeaveContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'
import LeaveRequestQuickNav from '../components/LeaveRequestQuickNav'
import getAnimationStyle from '../utils/getAnimationStyle'
import validateYesNo from '../utils/validateYesNo'

export default function New_Leave_Request_Covid_Family() {
  const { Covid, covidComplete, finishCovid } = useContext(LeaveContext)

  const [state, setState] = useState({
    employeeFullyVaccinated: null,
    employeeHasSymptoms: null,
    familyTestPositive: null,
    familyQuarantine: null,
    familyIsolate: null,
    employeeRemainHome: null,
    Error: {},
  })

  useEffect(() => {
    if (!Covid) {
      navigate('/Overview/');
    } else if (covidComplete) {
      setState(prev => ({
        ...prev,
        employeeFullyVaccinated: Covid.employeeFullyVaccinated,
        employeeHasSymptoms: Covid.employeeHasSymptoms,
        familyTestPositive: Covid.familyTestPositive,
        familyQuarantine: Covid.familyQuarantine,
        familyIsolate: Covid.familyIsolate,
        employeeRemainHome: Covid.employeeRemainHome,
      }))
    }
  }, [Covid, covidComplete])

  const onSubmit = (event) => {
    event.preventDefault()

    const Errors = {}
    // Validate Data
    if (!validateYesNo(state.employeeFullyVaccinated)) Errors.employeeFullyVaccinated = 'Select if Employee is Fully Vaccinated'
    if (!validateYesNo(state.employeeHasSymptoms)) Errors.employeeHasSymptoms = 'Select if Employee has Symptoms'
    if (!validateYesNo(state.familyTestPositive)) Errors.familyTestPositive = 'Select if Family Member Tested Positive'
    if (!validateYesNo(state.familyQuarantine)) Errors.familyQuarantine = 'Select if Family Member needs to Quarantine'
    if (!validateYesNo(state.familyIsolate)) Errors.familyIsolate = 'Select if Family Member can isolate away from you'
    if (!validateYesNo(state.employeeRemainHome)) Errors.employeeRemainHome = 'Select if Employee needs to remain home'

    if (Object.keys(Errors).length > 0) {
      setState(prev => ({ ...prev, Error: Errors }))
      return false
    }

    // Remove errors before saving to the store
    const covid = { ...state }
    delete covid.Error

    // Save to the store
    finishCovid(covid)
    if (state.familyIsolate === 'No' || state.employeeRemainHome === 'Yes') {
      navigate('/New_Leave_Request_Covid_Result_Yes/');
    } else {
      navigate('/New_Leave_Request_Covid_Result_No/');
    }
  }

  const handleStateInput = (e, field) => {
    e.persist()
    const { Error } = state
    delete Error[field]
    setState((prev) => {
      const rtn = { ...prev, Error }
      rtn[field] = e.target.value
      return rtn
    })
  }

  return (
    <PrivateRoute>
      <Layout activateLink>
        <SEO title="Covid Leave Request Family" />
        <div className="content-outline-998 leaveForm">
          <LeaveRequestQuickNav />
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            <p>
              Please complete the following questions to determine if you need to file a COVID leave request. Based on
              your responses, we will notify your manager if you can return to work immediately or need time off.
            </p>
          </div>
          <form className="LeaveFormEntry" onSubmit={onSubmit}>
            <h3>Family Request Questions:</h3>
            <div className={state.Error.employeeFullyVaccinated ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>
                Are you fully vaccinated (it has been more than 2 weeks since your final shot)? &nbsp;&nbsp;<br />
              </label>
              <select
                name="EEQ1"
                id="EEQ1"
                style={{ width: '7em' }}
                onChange={e => handleStateInput(e, 'employeeFullyVaccinated')}
                value={state.employeeFullyVaccinated}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.employeeHasSymptoms ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(1)}>
              <label>
                Are you experiencing any of the following symptoms: Fever, chills, cough,
                sore throat, shortness of breath, difficulty breathing, fatigue, muscle or body aches, headache, loss of
                taste or smell? &nbsp;&nbsp;
              </label>
              <select
                name="EEQ2"
                id="EEQ2"
                style={{ width: '7em' }}
                onChange={e => handleStateInput(e, 'employeeHasSymptoms')}
                value={state.employeeHasSymptoms}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.familyTestPositive ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(2)}>
              Did your family member test positive for COVID?
              <select
                name="EEQ3"
                id="EEQ3"
                style={{ width: '2.25em' }}
                onChange={e => handleStateInput(e, 'familyTestPositive')}
                value={state.familyTestPositive}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.familyQuarantine ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(3)}>
              Has your family member been advised to quarantine due to exposure?
              <select
                name="EEQ4"
                id="EEQ4"
                style={{ width: '2.25em' }}
                onChange={e => handleStateInput(e, 'familyQuarantine')}
                value={state.familyQuarantine}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.familyIsolate ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(4)}>
              Is your family member able to isolate away from you and other household members?
              <select
                name="EEQ5"
                id="EEQ5"
                style={{ width: '2.25em' }}
                onChange={e => handleStateInput(e, 'familyIsolate')}
                value={state.familyIsolate}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.employeeRemainHome ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(5)}>
              Do you need to remain at home to care for this family member?
              <select
                name="EEQ6"
                id="EEQ6"
                style={{ width: '2.25em' }}
                onChange={e => handleStateInput(e, 'employeeRemainHome')}
                value={state.employeeRemainHome}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <p className="clear">&nbsp;</p>
            <input type="submit" id="setp1submit" value="Continue &raquo;" />
          </form>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
